import { useAuthStore } from "@/stores";
import { useCookies } from "@vueuse/integrations/useCookies";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const cookies = useCookies(["sails.sid"]);
  const { state }: any = useAuthStore();
  // logInfo("auth.global --- auth", state);
  logInfo("auth.global --- to,from", to, from);
  logInfo("auth.global --- token", cookies.get("sails.sid"));

  if (cookies.get("sails.sid")) {
    // logInfo("已登录");
    // if (to.path === "/login") {
    //   logInfo("已登录, 去首页");
    //   // return navigateTo("/");
    //   return navigateTo("/product/LiDAR360MLS");
    // }
  } else {
    logInfo("未登录", state?.pages, state?.pages?.includes(to.path));
    if (state?.pages?.includes(to.path)) {
      logInfo("未登录, 去登录页");
      setTimeout(() => {
        return navigateTo("/login");
      }, 0);
    }
  }
  // return navigateTo(to.path);
  // const res = await useFetch(
  //   constants.SENDMESSAGE_URL + api.getConfig + "home",
  //   {
  //     method: "post",
  //   }
  // );
  // console.log("res 中间件内", res);
});
