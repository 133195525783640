/**
 * 购物车
 */
import { ref, reactive, computed, watch } from "vue";

export const useCartStore = defineStore(
  "gvi-mall-cart",
  () => {
    // 状态
    const cartState = reactive({
      list: [
        {
          id: 1,
          name: "商品1",
          price: 10,
          quantity: 1,
          img: "https://data-file-platform.s3.ap-southeast-1.amazonaws.com/gvi-us-2024-06-25MTcxOTMwMTkwMzQ2MA%3D%3D.png",
          isSelected: true,
        },
        {
          id: 2,
          name: "商品2",
          price: 20,
          quantity: 2,
          img: "https://data-file-platform.s3.ap-southeast-1.amazonaws.com/gvi-us-2024-06-25MTcxOTMwMTkwMzQ2MA%3D%3D.png",
          isSelected: false,
        },
        {
          id: 3,
          name: "商品3",
          price: 30,
          quantity: 3,
          img: "https://data-file-platform.s3.ap-southeast-1.amazonaws.com/gvi-us-2024-06-25MTcxOTMwMTkwMzQ2MA%3D%3D.png",
          isSelected: true,
        },
        {
          id: 4,
          name: "商品4",
          price: 40,
          quantity: 4,
          img: "https://data-file-platform.s3.ap-southeast-1.amazonaws.com/gvi-us-2024-06-25MTcxOTMwMTkwMzQ2MA%3D%3D.png",
          isSelected: false,
        },
      ],
      list2: [
        {
          id: "04b78ca5c590473dbc78b81262592621",
          goodsName: "LiDAR360(V8.0)",
          isSelect: "TRUE",
          price: 29800.0,
          goodsCode: "LiDAR360",
          file: {
            id: "c2288c8d28f84372b77c95c1beee7506",
            fileUrl:
              "http://licloud-oss.oss-cn-shenzhen.aliyuncs.com/shoppingmall/LiDAR360_V8.png?Expires=2045722511&OSSAccessKeyId=LTAI4v1iU6fOS71K&Signature=C56wTIsXp1uKvsuQVsxjL%2BnvsiM%3D",
            fileType: "png",
          },
          sub: [
            {
              id: "bea960d81c6a41109147874dc35c5428",
              goodsName: "Annual Subscription",
              goodsCode: "LiDAR360_Annual_Subscription",
              total: 4,
              price: 4300.0,
            },
            {
              id: "c51b2cf728cf483f8f04872b788b9bc4",
              goodsName: "Photo",
              goodsCode: "LiDAR360_Combination_Mode_Photo",
              total: 6,
              price: 2100.0,
            },
          ],
        },
      ],
      goodsTotalPrice: 0,
    });

    // 计算属性
    const sum = computed(() => {
      let total = 0;
      cartState.list.forEach((item) => {
        if (item.isSelected) {
          total += item.price * item.quantity;
        }
      });
      return total;
    });

    // 方法
    const updateItemQuantity = (id, quantity) => {
      console.log("updateItemQuantity---id, quantity =>", id, quantity);

      cartState.list.forEach((item) => {
        if (item.id === id) {
          item.quantity = quantity;
        }
      });
      console.log("sum", sum.value);
    };

    const setGoodsTotalPrice = (totalPrice) => {
      cartState.goodsTotalPrice = totalPrice;
    };

    return {
      cartState,
      sum,
      updateItemQuantity,
      setGoodsTotalPrice,
    };
  }
  // {
  //   persist: true,
  // }
);
