export default {
  //csrfToken
  csrfToken: "/csrfToken",
  // 登录
  login: "/api/v1/entrance/login",
  // 注册
  register: "/api/v1/onlineActive/us/sendRegisterEmail",
  // 注册提交表单
  register2: "/api/v1/onlineActive/us/register",
  // 忘记密码
  forgetPassword: "/api/v1/onlineActive/us/resetPassword/sendEmail",
  // 忘记密码提交表单
  forgetPassword2: "/api/v1/onlineActive/us/resetPassword/submit",
  // 获取轮播图
  getBannerList: "/api/v1/shoppingMall/public/home/carousel",
  // 获取最畅销接口
  getBestSell: "/api/v1/shoppingMall/public/home/bestSell",
  // 获取商品模块接口
  getGoodsShow: "/api/v1/shoppingMall/public/home/homeGoods",
  // 获取模块接口（Applications）
  getApplications: "/api/v1/shoppingMall/public/home/module",
  //获取导航
  getNavigation: "/api/v1/shoppingMall/public/navigation",
  //获取热点推荐接口
  getHotRecommend: "/api/v1/shoppingMall/public/navigation/recommend",
  //搜索
  search: "/api/v1/shoppingMall/public/navigation/search",
  //获取个人信息
  getMy: "/api/v1/account/me",
  //获取个人头像
  getMyAvatar: "/api/v1/download/",
  //获取行业列表
  industryList: "/api/v1/onlineActive/us/industry",
  // 退出登录
  logout: "/api/v1/account/logout",
  // 获取用户信息
  getUserInfo: "/api/v1/account/getUserBaseInfo",
  //获取个人订单
  getOrderList: "/api/v1/shoppingMall/order/pageList",
  //获取个人订单详情
  getOrderDetail: "/api/v1/shoppingMall/order/details",
  //取消订单
  cancelOrder: "/api/v1/shoppingMall/order/cancel",
  // 上传图片
  uploadImage: "/api/v1/account/getUserBaseInfo",
  // 商品详情
  getGoodsDetail: "/api/v1/shoppingMall/goods/getBaseInfo",
  // 商品详情2
  getProductInfo: "/api/v1/shoppingMall/goods/getProductInfo",
  // 商品详情-问答
  getQuestionInfo: "/api/v1/shoppingMall/goods/getQuestionInfo",
  // 购物车列表
  cartList: "/api/v1/shoppingMall/cart/list",
  // 购物车删除单个   /api/v1/shoppingMall/cart/deleteById?id={商品id}
  cartDel: "/api/v1/shoppingMall/cart/deleteById",
  // 购物车添加   /api/v1/shoppingMall/cart/add
  cartAdd: "/api/v1/shoppingMall/cart/add",
  // 购物车+
  cartIncrease: "/api/v1/shoppingMall/cart/increase",
  // 购物车-
  cartReduce: "/api/v1/shoppingMall/cart/reduce",
  // 勾选购物车，计算价格接口
  selectTotal: "/api/v1/shoppingMall/cart/selectTotal",
  // 下单结算接口     /api/v1/shoppingMall/order/createOrder?sid='1111'
  createOrder: "/api/v1/shoppingMall/order/createOrder",
  // 订单列表的结算接口
  payByOrder:'/api/v1/shoppingMall/order/payByOrder',
  //发生发票
  invoice: "/api/v1/shoppingMall/order/sendInvoice",
  //取消订单
  request: "/api/v1/shoppingMall/order/orderRefund",
};
