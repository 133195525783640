import { default as cartJchSZxeYePMeta } from "E:/shuzilvtu/gvi-mall/pages/cart.vue?macro=true";
import { default as checkoutl5zuXqZvAbMeta } from "E:/shuzilvtu/gvi-mall/pages/checkout.vue?macro=true";
import { default as demoxOLlphCBzuMeta } from "E:/shuzilvtu/gvi-mall/pages/demo.vue?macro=true";
import { default as forgetPasswd562c6tEP4cMeta } from "E:/shuzilvtu/gvi-mall/pages/forgetPasswd.vue?macro=true";
import { default as indexK1c2cxcNgnMeta } from "E:/shuzilvtu/gvi-mall/pages/index.vue?macro=true";
import { default as loginAcpcLtixxpMeta } from "E:/shuzilvtu/gvi-mall/pages/login.vue?macro=true";
import { default as myKCTJHvv1e1Meta } from "E:/shuzilvtu/gvi-mall/pages/my.vue?macro=true";
import { default as pay_45cancelEJjjeHEwzDMeta } from "E:/shuzilvtu/gvi-mall/pages/pay-cancel.vue?macro=true";
import { default as pay_45successelmgTBPnbpMeta } from "E:/shuzilvtu/gvi-mall/pages/pay-success.vue?macro=true";
import { default as add_45successKTcn2fUMT7Meta } from "E:/shuzilvtu/gvi-mall/pages/product/[name]/add-success.vue?macro=true";
import { default as indexsYRJRCbRjdMeta } from "E:/shuzilvtu/gvi-mall/pages/product/[name]/index.vue?macro=true";
import { default as signupx8yxDb6o5wMeta } from "E:/shuzilvtu/gvi-mall/pages/signup.vue?macro=true";
export default [
  {
    name: "cart",
    path: "/cart",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "demo",
    path: "/demo",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: "forgetPasswd",
    path: "/forgetPasswd",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/forgetPasswd.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "my",
    path: "/my",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/my.vue").then(m => m.default || m)
  },
  {
    name: "pay-cancel",
    path: "/pay-cancel",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/pay-cancel.vue").then(m => m.default || m)
  },
  {
    name: "pay-success",
    path: "/pay-success",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/pay-success.vue").then(m => m.default || m)
  },
  {
    name: "product-name-add-success",
    path: "/product/:name()/add-success",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/product/[name]/add-success.vue").then(m => m.default || m)
  },
  {
    name: "product-name",
    path: "/product/:name()",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/product/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("E:/shuzilvtu/gvi-mall/pages/signup.vue").then(m => m.default || m)
  }
]