import revive_payload_client_4sVQNw7RlN from "E:/shuzilvtu/gvi-mall/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "E:/shuzilvtu/gvi-mall/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "E:/shuzilvtu/gvi-mall/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "E:/shuzilvtu/gvi-mall/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "E:/shuzilvtu/gvi-mall/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "E:/shuzilvtu/gvi-mall/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "E:/shuzilvtu/gvi-mall/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "E:/shuzilvtu/gvi-mall/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "E:/shuzilvtu/gvi-mall/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "E:/shuzilvtu/gvi-mall/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "E:/shuzilvtu/gvi-mall/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_AUP22rrBAc from "E:/shuzilvtu/gvi-mall/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import components_modal_register_aSIvgRJjr1 from "E:/shuzilvtu/gvi-mall/plugins/components-modal-register.js";
import directives_8CcCirWtnE from "E:/shuzilvtu/gvi-mall/plugins/directives.ts";
import hook_LTDFb6k0en from "E:/shuzilvtu/gvi-mall/plugins/hook.ts";
import wow_client_utB3fCnsxi from "E:/shuzilvtu/gvi-mall/plugins/wow.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  plugin_AUP22rrBAc,
  components_modal_register_aSIvgRJjr1,
  directives_8CcCirWtnE,
  hook_LTDFb6k0en,
  wow_client_utB3fCnsxi
]