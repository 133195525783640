export default defineNuxtPlugin((nuxtApp) => {
  // nuxtApp.hook("page:start", () => {
  //   console.log("nuxtApp.hook --- page:start");
  // });
  // nuxtApp.hook("app:created", () => {
  //   console.log("nuxtApp.hook --- app:created");
  // });
  // nuxtApp.hook("vue:setup", () => {
  //   console.log("nuxtApp.hook --- vue:setup");
  // });
  // nuxtApp.hook("app:mounted", () => {
  //   console.log("nuxtApp.hook --- app:mounted");
  // });
  // nuxtApp.hook("page:start", () => {
  //   console.log("nuxtApp.hook --- page:start");
  // });
  // nuxtApp.hook("page:finish", () => {
  //   console.log("nuxtApp.hook --- page:finish");
  // });
  // nuxtApp.hook("app:redirected", () => {
  //   console.log("nuxtApp.hook --- app:redirected");
  // });
  // nuxtApp.hook("ready", () => {
  //   console.log("nuxtApp.hook --- ready");
  // });
  // nuxtApp.hook("close", () => {
  //   console.log("nuxtApp.hook --- close");
  // });
});
