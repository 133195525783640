<template>
  <div>
    <!-- <NuxtWelcome /> -->
    <NuxtPage></NuxtPage>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

useHead({
  meta: [
    {
      name: "viewport",
      content:
        "width=device-width,initial-scale=1.0,maximum-scale=1.0, user-scalable=0",
    },
  ],
});
</script>

<style lang="less" scoped></style>
