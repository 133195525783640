import CryptoJS from "crypto-js";

logInfo("BASE_URL", import.meta.env.VITE_API_URL);

// 变量
export const constants = {
  /** 本地调试 请求域名 */
  BASE_URL: import.meta.env.VITE_API_URL,
  ANIMATE_NAME: {
    title: "wow animate__bounceIn",
  },
  // 设备类型
  deviceType: {
    pc: "PC",
    mobile: "MOBILE",
    ipad: "FLAT",
  },
};

// 页面跳转
export const linkTo = (url) => {
  if (url) {
    window.location.href = url;
  }
};

// 获取当前浏览器的域名及端口  'http://192.168.10.140:5189'
export const getCurrentDomainAndPortWithPrefix = () => {
  // 获取当前网页的域名
  var origin = window.location.origin;
  return origin;
};

/**
 * 获取拓展字段名
 * @param {*} v  当前数据
 * @param {*} field 拓展字段名
 * @returns
 */
export const getExtendMapField = (v, field) => {
  if (v.extendMap && v.extendMap[field] && v.extendMap[field].length > 0) {
    return v.extendMap[field][0].extValue;
  }
  return "";
};

// // 本地跑项目，判断是否为亚马逊url地址或后台配置的文件资源地址；如果不是，则用测试环境地址+相对路径
// export const getImgUrl = (url, fromStr) => {
//   if (!url) {
//     return url;
//   }
//   const rpUrl =
//     fromStr && fromStr === "tags" ? url : url.replace("/gvi-minio", ""); // 去掉代理路径(tags没有minio代理所以不需要去掉)

//   let url2 = "";
//   if (fromStr && fromStr === "tags") {
//     url2 = constants.FILE_PREFIX ? constants.FILE_PREFIX + rpUrl : url; // 如果FILE_PREFIX存在，才拼接（测试环境或者线上环境不需要处理）
//   } else {
//     url2 = constants.IMG_PREFIX ? constants.IMG_PREFIX + rpUrl : url; // 如果IMG_PREFIX存在，才拼接（测试环境或者线上环境不需要处理）
//   }
//   return url.includes("https://data-file") ||
//     url.includes("https://") ||
//     url.includes("http://")
//     ? url
//     : url2;
// };

// // 获取首页资源url
// export const getHomeImgUrl = (url) => {
//   return url.includes("https://") || url.includes("http://")
//     ? url
//     : constants.HOME_IMG_PREFIX + url;
// };

// 将moduleList对象转换为数组
export function transformDataToArrays(data) {
  const result = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (Array.isArray(value) && value.length > 0) {
        result.push({ moduleType: key, moduleData: value });
      }
    }
  }
  return result;
}

// 将moduleList对象转换为数组，并且根据字段排序
export function transformAndSortData(data) {
  // 将数据转换为数组形式
  const transformedArray = Object.keys(data).map((key) => ({
    moduleType: key,
    moduleData: data[key],
  }));

  // 根据每个模块的第一条数据的 moduleSort 字段进行排序
  transformedArray.sort((a, b) => {
    const sortA = a.moduleData[0]?.moduleSort || 0;
    const sortB = b.moduleData[0]?.moduleSort || 0;
    return sortA - sortB;
  });

  return transformedArray;
}

/**
 * 获取组件
 * @param {*} key
 * @param {*} componentMap
 * @returns
 */
export function getDynamicComponent(key, componentMap) {
  for (const mapping of componentMap) {
    if (mapping.pattern.test(key)) {
      return mapping.component;
    }
  }
  return null;
}

/**
 * 自定义console.log方法
 * @param {*} logName log名称
 * @param {*} logData log值
 * @param {*} logData2 第二个log值
 *    logInfo('入参', {a: 1, b: 2});
 */
export function logInfo(logName, logData = undefined, logData2 = undefined) {
  // 获取当前的日期和时间，格式化为 [年月日 时分秒]
  const now = new Date();
  const formattedTime = `${now.getFullYear()}-${(now.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")} ${now
    .getHours()
    .toString()
    .padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}:${now
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;

  // 在控制台打印格式化的日志信息
  if (logData && logData2) {
    return console.log(
      `Log：[${formattedTime}] ${logName} =>`,
      logData,
      logData2
    );
  } else if (logData && !logData2) {
    return console.log(`Log：[${formattedTime}] ${logName} =>`, logData);
  }
  return console.log(`Log：[${formattedTime}] ${logName}`);
}

// 判断ipad
export function isIpad() {
  var ua = window.navigator.userAgent;
  var IsIPad = false;
  if (/ipad/i.test(ua)) {
    IsIPad = true;
  }
  // iPad from IOS13
  var macApp = ua.match(/Macintosh/i) != null;
  if (macApp) {
    // need to distinguish between Macbook and iPad
    var canvas = document.createElement("canvas");
    if (canvas != null) {
      var context =
        canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
      if (context) {
        var info = context.getExtension("WEBGL_debug_renderer_info");
        if (info) {
          var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
          if (renderer.indexOf("Apple") != -1) IsIPad = true;
        }
      }
    }
  }
  return IsIPad;
}

// 判断移动端
export function isMobile() {
  return navigator.userAgent.match(
    /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i // |iPad
  );
}

/**
 * DES加密
 * @param {*} data 准备加密的数据
 * @param {*} key key
 * @returns
 */
export function encryptByDES(data, key = "greenvalleytrial") {
  let keyHEX = CryptoJS.enc.Utf8.parse(key);
  // 使用DES算法对数据进行加密
  var encrypted = CryptoJS.DES.encrypt(JSON.stringify(data), keyHEX, {
    iv: "", // 初始化向量为空
    mode: CryptoJS.mode.ECB, // 使用ECB模式
    padding: CryptoJS.pad.ZeroPadding, // 填充方式
  });
  return encrypted.toString();
  // console.log("encryptByDES obj =>", encryptByDES({ a: "1111", b: 222 }));
}
export function encryptByDES2(data, key = "greenvalleytrial") {
  let keyHEX = CryptoJS.enc.Utf8.parse(key);
  // 使用DES算法对数据进行加密
  var encrypted = CryptoJS.DES.encrypt(
    CryptoJS.enc.Utf8.parse(JSON.stringify(data).slice(1, -1)),
    keyHEX,
    {
      mode: CryptoJS.mode.ECB, // 使用ECB模式
      padding: CryptoJS.pad.Pkcs7, // 填充方式
    }
  );
  return encrypted.toString();
  // console.log("encryptByDES obj =>", encryptByDES({ a: "1111", b: 222 }));
}
/**
 * DES解密
 * @param {*} data 准备解密的数据
 * @param {*} key key
 * @returns
 */
export function decryptByDES(data, key = "greenvalleytrial") {
  let keyHEX = CryptoJS.enc.Utf8.parse(key);

  // 使用DES算法对数据进行解密
  var decrypted = CryptoJS.DES.decrypt(data, keyHEX, {
    iv: "", // 初始化向量为空
    mode: CryptoJS.mode.ECB, // 使用ECB模式
    padding: CryptoJS.pad.ZeroPadding, // 填充方式
  });

  // 将解密后的数据转换为UTF-8字符串
  return CryptoJS.enc.Utf8.stringify(decrypted);
  // console.log("decryptByDES keyId", decryptByDES(data.keyId));
}

// uuid 生成
export function uuid() {
  return (
    Date.now().toString(36) + Math.random().toString(36).substring(2, 10)
  ).substring(0, 16);
}
