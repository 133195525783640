/**
 * 权限 + 用户信息
 */
import { reactive } from "vue";

export const useAuthStore = defineStore(
  "gvi-mall-auth",
  () => {
    let state = reactive({
      pages: ["/cart", "/my", "/checkout"], // 需要鉴权的页面(必须登录）
      userInfo: null, // 用户信息
      isLogin: false, // 是否登录
      csrfToken: "", // csrfToken
    });

    const getCsrfToken = async () => {
      const res = await useFetch(constants.BASE_URL + api.csrfToken, {
        method: "GET",
      });
      state.csrfToken = res.data._rawValue._csrf;
      console.log("res store内", res, state.csrfToken);
      return state.csrfToken;
    };

    const setUserInfo = (userInfo) => {
      state.userInfo = userInfo;
    };

    const getUserInfo = async (sid) => {
      await nextTick();
      const res = await useFetch(constants.BASE_URL + api.getMy + '?sid=' + sid,
        { method: 'GET' })
      state.userInfo = res.data.value;
      return state.userInfo;
    };

    const delUserInfo = () => {
      state.userInfo = null;
    };

    return {
      state,
      // getData,
      getCsrfToken,
      setUserInfo,
      delUserInfo,
      getUserInfo
    };
  },
  // {
  //   persist: true,
  // }
);
